<!-- 商品评价 -->
<template>
    <div class="comment-container">
        <div class="comment-header">
            <div class="flex align-c">
                <div class="grade-wrapper">
                    <p>{{commentInfo.productLevel}}</p>
                    <p class="font-12">宝贝评分</p>
                </div>
                <div class="flex-column justify-c">
                    <div class="flex align-c">
                        <span class="color-666 font-12 mr-10">好评</span>
                        <div class="progress-wrapper">
                            <el-progress color="#FF0036" :percentage="commentInfo.goodReview || 0"></el-progress>
                        </div>
                    </div>
                    <div class="flex align-c mt-10">
                        <span class="color-666 font-12 mr-10">中评</span>
                        <div class="progress-wrapper">
                            <el-progress color="#FF0036" :percentage="commentInfo.mediumReview || 0"></el-progress>
                        </div>
                    </div>
                    <div class="flex align-c mt-10">
                        <span class="color-666 font-12 mr-10">差评</span>
                        <div class="progress-wrapper">
                            <el-progress color="#FF0036" :percentage="commentInfo.badReview || 0"></el-progress>
                        </div>
                    </div>
                </div>
            </div>
            <ul class="comment-level flex flex-wrap">
                <li v-for="(item, index) in levelList" :key="index" @click="levelChangeFn(item.level)"
                    :class="levelSelect == item.level ? 'active' : ''">{{item.levelName}}({{item.nums}})</li>
            </ul>
            <ul class="comment-list">
                <li v-for="item in commentList" :key="item.id" class="flex">
                    <img class="avatar" :src="item.headimgurl"/>
                    <div class="flex-1">
                        <div class="flex align-c justify-s-b">
                            <div class="flex-column">
                                <div class="mb-5">{{item.nickname}}</div>
                                <div class="flex align-c">
                                    <span class="color-999 font-12">评分</span>
                                    <el-rate v-model="item.level" disabled :colors="['#ee0a24', '#ee0a24', '#ee0a24']" disabled-void-color="#eee"></el-rate>
                                </div>
                            </div>
                        </div>
                        <p class="mt-10">{{item.content}}</p>
                        <div class="flex flex-wrap image-wrapper">
                            <img v-for="(img, i) in item.imageList" :key="i" :src="img"/>
                        </div>
                    </div>
                    <div class="color-999 time">{{item.createtime | dateFormat('YYYY年MM月DD日 HH:mm')}}</div>
                </li>
            </ul>
            <p v-if="commentList.length == 0" class="tips">暂无相关评论~</p>
        </div>
        <el-pagination v-if="total>perPage" @current-change="pageChange" :current-page="currPage" :page-size="perPage"
                :total="total" layout="total, prev, pager, next, jumper" background>
        </el-pagination>
    </div>
</template>

<script>

export default {
    name: 'Comment',
    components: {},

    props: {
        productId: {
            type: [Number, String]
        }
    },

    data() {
        return {
            commentInfo: {},
            commentList: [],
            levelList: [],
            levelSelect: '',
            currPage: 1,
            perPage: 10,
            total: 0
        };
    },

    created() {
        if(this.productId)
            this.getComment(false)
    },

    methods: {
        getComment(status) {
            if(!status) this.currPage = 1
            this.$https.get(this.$api.comments,{
                productId: this.productId,
                level: this.levelSelect,
                offset: this.currPage,
                limit: this.perPage
            })
            .then((res) =>{
                this.commentInfo = res.data
                this.levelList = res.data.levelList
                this.commentList = res.data.pageUtils.rows
            })
        },

        //分页
        pageChange(val) {
            if(this.currPage != val){
                this.currPage = val
                this.getComment(true)
            }  
        },

        levelChangeFn(level) {
            if(level == this.levelSelect) return
            this.levelSelect = level
            this.getComment(false)
        }
    }
}
</script>
<style lang='scss'>
    .comment-container .el-rate__icon{
        font-size: 16px;
        margin-right: 0;
    }
</style>
<style lang='scss' scoped>
    .grade-wrapper{
        padding: 5px 30px;
        margin-right: 30px;
        border-right: 1px solid #dedede;
        p:first-child{
            color: #FF0036;
            font-size: 34px;
            text-align: center;
            margin-bottom: 10px;
        }
    }
    .progress-wrapper{
        width: 200px;
    }
    .comment-level{
        padding: 15px 0 20px;
        border-bottom: 1px solid #dedede;
        li{
            height: 26px;
            line-height: 26px;
            padding: 0 12px;
            margin: 15px 15px 0 0;
            font-size: 12px;
            background-color: #e5e5e5;
            border-radius:3px;
            cursor: pointer;
            &:hover{
                background-color: rgba(255, 0, 54, .12);
            }
            &.active{
                color: #fff;
                background-color:  rgba(255, 0, 54, 1);
            }
        }
    }
    .comment-list{
        li{
            padding: 20px 0;
            border-bottom: 1px solid #dedede;
            &:last-child{
                border-bottom: none;
            }
            .avatar{
                width: 35px;
                height: 35px;
                border-radius: 50%;
                margin-right: 10px;
            }
            .image-wrapper{
                img{
                    width: 50px;
                    height: 50px;
                    margin: 10px 10px 0 0;
                }
            }
            .time{
                margin-left: 150px;
            }
        }
    }
    .tips{
        padding: 30px 0;
        text-align: center;
    }
</style>